div.cell {
  display: table-cell;
  text-align: center;
  border-style: solid;
  border-width: thin;
  font-size: 10px;
}
div.cellSelected {
  background-color:blue;
}
div.cellNotSelected {
  background-color:gray;
}
div.value:hover {
  background-color: black;
  color: white;
}
div.row {
  display: table-row;
  border-style: solid;
  border-width: 1px;
}
div.small {
}
div.header {
  display: table-row;
  font-weight: bold;
  text-decoration: underline;
  text-align:center;
  font-size: 10px;
}
div.header div {
  vertical-align: bottom;
  padding: 5px 5px;
}
div.row div {
  padding: 5px 5px;
}
div.table {
  display: table;
  margin: 5px;
}
div.question {
  text-align: left;
}
